* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: sans-serif;
}

header {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.07), 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  color: #8A5D38;
  color: #202020;
}

.nav-area {
  display: flex;
  align-items: center;
  max-width: 100%;
  margin-left: 7Vw;
  margin-top: 2Vh;
}  

.logo {
  text-decoration: none;
  font-size: 30px;
  color: inherit;
  position: fixed;
  top: 10px;
  left: 10px;
}

.usuario {
  text-decoration: none;
  font-size: 1.0em;
  font-weight: 700;
  position: fixed;
  top: 3Vh;
  margin-left: 80Vw;
  text-decoration: underline;
}

.versao {
  text-decoration: none;
  font-size: 10px;
  font-weight: 100;
  position: fixed;
  top: 6Vh;
  margin-left:10Vw;
  text-decoration: underline  
}


.bt_sair {
  position: fixed;
  top: 5px;
  left: 94%;
}

.menus {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  list-style: none;
}

.menu-items {
  position: relative;
  font-size: 12px;
}

.menu-items a {
  display: block;
  font-size: inherit;
  color: inherit;
  text-decoration: none;
}

.menu-items button {
  display: flex;
  align-items: center;
  color: inherit;
  font-size: inherit;
  border: none;
  background-color: transparent;
  cursor: pointer;
  width: 100%;
}

button span {
  margin-left: 3px;
}

.menu-items > a, .menu-items button {
  text-align: left;
  padding: 0.7rem 1rem;
}

.menu-items a:hover,
.menu-items button:hover {
  background-color: #f2f2f2;
}

.arrow::after {
  content: "";
  display: inline-block;
  margin-left: 0.28em;
  vertical-align: 0.09em;
  border-top: 0.42em solid;
  border-right: 0.32em solid transparent;
  border-left: 0.32em solid transparent;
}

.dropdown {
  position: absolute;
  right: 25;
  left: auto;
  box-shadow: 0 10px 15px -3px rgba(46, 41, 51, 0.08),
    0 4px 6px -2px rgba(71, 63, 79, 0.16);
  font-size: 0.875rem;
  z-index: 9999;
  min-width: 15rem;
  padding: 0.1rem 0;
  list-style: none;
  background-color: #fff;
  border-radius: 1.5rem;
  display: none;
}

.dropdown.show {
  display: block;
}

.dropdown .dropdown-submenu {
  position: absolute;
  left: 100%;
  top: -7px;
}

/* content */

.content {
  max-width: 100%;
  margin: 0 auto;
}

.content h2 {
  margin-bottom: 1rem;
}

.content a {
  color: #cc3852;
  margin-right: 10px;
}